<template>
  <v-chip
    small
    :color="color"
    :text-color="textColor"
    :to="to"
    :class="classes"
    @click="$emit('click', $event)"
  >
    <v-img
      v-if="avatarUrl"
      :src="avatarUrl"
      :width="15"
      class="mr-1"
    />
    <slot></slot>
    <v-icon
      v-if="appendIcon"
      class="ml-1"
      small
    >
      {{ appendIcon }}
    </v-icon>
  </v-chip>
</template>

<script>
import { COLORS } from '../../utils/constants';
export default {
  props: {
    color: { type: String, default: '' },
    avatarUrl: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
    to: { type: String, default: '' },
    block: { type: Boolean, default: false }
  },
  computed: {
    hasClickEventListener() {
      return (this.$listeners && this.$listeners.click) || this.to;
    },
    colors() {
      return Object.keys(COLORS).map((x) => COLORS[x]);
    },
    colorObj() {
      return this.colors.find((x) => x.HEX === this.color || x.NAME === this.color);
    },
    classes() {
      const list = ['font-weight-semibold'];
      if (this.hasClickEventListener) {
        list.push('hover-pointer');
      } else {
        list.push('no-events');
      }
      if (this.colorObj && this.colorObj.NAME === COLORS.WHITE.NAME) {
        list.push('border border-grey');
      }
      if (this.block) {
        list.push('w-full');
      }
      return list;
    },
    textColor() {
      if (
        this.colorObj &&
        this.colorObj.NAME !== COLORS.GREY.NAME &&
        this.colorObj.NAME !== COLORS.WHITE.NAME
      ) {
        if (this.colorObj.NAME.includes('Light')) {
          const newColor = this.colors.find(
            (x) => x.NAME === this.colorObj.NAME.replace('Light', '')
          );
          if (newColor) {
            return newColor.HEX;
          }
        } else {
          return COLORS.WHITE.HEX;
        }
      }
      return COLORS.BLACK.HEX;
    }
  }
};
</script>
<style>
.v-chip.v-size--small {
  font-size: 11px;
}
.w-full .v-chip__content {
  margin-left: auto;
  margin-right: auto;
}
.no-events.v-chip:hover::before {
  opacity: 0 !important;
}
</style>
