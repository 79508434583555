<template>
  <v-row v-if="!showEdit">
    <v-col cols="12">
      <mcs-btn
        block
        outlined
        color="black"
        large
        prepend-icon="mdi-plus-circle"
        @click="edit"
      >
        Add Address
      </mcs-btn>
    </v-col>
    <v-col
      v-for="address in addressesToShow"
      :key="address.id"
      cols="12"
    >
      <mcs-sheet
        v-ripple
        outlined
        dense
        class="hover-pointer"
        small-radius
        @click="edit(address)"
        @click.native="edit(address)"
      >
        <v-row dense>
          <v-col
            cols="auto"
            class="my-auto"
          >
            <v-icon small>mdi-map-marker</v-icon>
          </v-col>
          <v-col class="my-auto">
            {{ address.line1 }}
            <template v-if="address.line2">
              {{ address.line2 }}
            </template>
            {{ address.city }}, {{ address.state }} {{ address.postalCode }}
          </v-col>
          <v-spacer class="spacer-tight" />
          <v-col
            v-if="address.isDefault"
            cols="auto"
            class="my-auto"
          >
            <v-icon
              small
              color="accent"
            >
              mdi-star
            </v-icon>
          </v-col>
          <v-col
            cols="auto"
            class="my-auto"
          >
            <mcs-btn
              text
              color="primary"
              class="px-0"
              small
              >Edit</mcs-btn
            >
          </v-col>
        </v-row>
      </mcs-sheet>
    </v-col>
    <Portal :to="`drawerAppend-${drawerId}`">
      <v-divider />
      <v-row class="pa-2 ma-0">
        <v-col cols="12">
          <mcs-btn
            block
            color="black"
            large
            @click="$emit('close')"
          >
            {{ backText }}
          </mcs-btn>
        </v-col>
      </v-row>
    </Portal>
  </v-row>
  <AddressForm
    v-else
    :id="addressId"
    :drawerId="drawerId"
    @save="handleSave"
  />
</template>

<script>
import { orderBy } from 'lodash';
import { mapGetters } from 'vuex';
import AddressForm from './Form.vue';
export default {
  name: 'ManageAddresses',
  components: { AddressForm },
  props: { drawerId: { type: String, default: '' }, backText: { type: String, default: '' } },
  data() {
    return {
      showEdit: false,
      addressId: null
    };
  },
  computed: {
    addressesToShow() {
      return orderBy(this.addresses, ['isDefault', 'id'], ['desc', 'asc']);
    },
    ...mapGetters({ addresses: 'user/addresses' })
  },
  methods: {
    edit(address) {
      this.addressId = address ? address.id : null;
      this.showEdit = true;
    },
    handleSave() {
      this.showEdit = false;
      this.addressId = null;
      this.$emit('close');
    }
  }
};
</script>
