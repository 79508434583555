import { ENUMS, ROUTES } from '../constants';
export default {
  computed: {
    parentId() {
      let val = this.$route.params.parentId;
      if (val && val.length > 0) {
        val = parseInt(val);
      }
      return val || null;
    },
    collectionId() {
      let val = this.$route.params.id;
      if (val && val.length > 0) {
        val = parseInt(val);
      }

      // enables the use of the Collection Header card on the Dashboard
      if (!val && this.$store.getters['user/isCollectionAdmin']) {
        val = this.$store.getters['user/profile'].collectionId;
      }
      if (!val && this.$store.getters['user/isMasterAccountAdmin'] && this.$store.getters['admin/collections/list'].length === 1) {
        val = this.$store.getters['admin/collections/list'][0].id;
      }

      return val || null;
    },
    links() {
      return [
        {
          title: 'Templates',
          path: this.getCollectionsDetailPath(ENUMS.ASSET_TYPES.TEMPLATES)
        },
        {
          title: 'Images',
          path: this.getCollectionsDetailPath(ENUMS.ASSET_TYPES.IMAGES)
        },
        {
          title: 'Colors',
          path: this.getCollectionsDetailPath(ENUMS.ASSET_TYPES.COLORS)
        }
      ];
    }
  },
  methods: {
    getPublishedColor(published, hex) {
      if (published) {
        return 'successLight';
      }
      return 'accentLight';
    },
    getCollectionsDetailPath(type, parentId, collectionId) {
      const isCollectionAdmin = this.$store.getters['user/isCollectionAdmin'];
      if (!collectionId) {
        collectionId = this.collectionId;
      }
      let link = (isCollectionAdmin ? ROUTES.ADMIN_TEAM_DETAIL : ROUTES.ADMIN_COLLECTIONS_DETAIL).PATH;
      if (type) {
        link = (isCollectionAdmin ? ROUTES.ADMIN_TEAM_DETAIL_TYPE : ROUTES.ADMIN_COLLECTIONS_DETAIL_TYPE).PATH;
      }
      if (parentId) {
        link = (isCollectionAdmin ? ROUTES.ADMIN_TEAM_DETAIL_TYPE_CHILD : ROUTES.ADMIN_COLLECTIONS_DETAIL_TYPE_CHILD).PATH;
      }

      return link.replace(':typeId', type).replace(':id', collectionId).replace(':parentId', parentId);
    }
  }
};
