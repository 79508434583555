<template>
  <v-dialog
    v-if="profile.requirePasswordChange"
    v-model="dialog"
    persistent
    max-width="360"
  >
    <mcs-card
      title="Change Password"
      subtitle="Please set a password before continuing."
    >
      <v-form ref="form">
        <v-text-field
          v-model="password"
          :rules="[$rules.required, $rules.minlength(password, 6)]"
          outlined
          type="password"
          label="New Password"
        />
        <v-text-field
          v-model="confirmPassword"
          :rules="[confirm]"
          outlined
          type="password"
          label="Confirm Password"
        />
        <mcs-btn
          block
          large
          color="black"
          :loading="saving"
          @click="save"
        >
          Save
        </mcs-btn>
      </v-form>
    </mcs-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ForcePassword',
  data() {
    return {
      dialog: true,
      password: '',
      confirmPassword: '',
      saving: false
    };
  },
  computed: { ...mapGetters({ profile: 'user/profile' }) },
  methods: {
    confirm(val) {
      return val === this.password || "Password doesn't match";
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        this.$store
          .dispatch('user/updatePassword', { password: this.password })
          .then((data) => {
            if (this.$refs.form) {
              this.$refs.form.reset();
            }
            this.$root.$snackbarSuccess('Password updated');
          })
          .catch((error) => {
            this.logError(error, 'An error occurred while changing your password');
          })
          .finally(() => {
            this.saving = false;
          });
      }
    }
  }
};
</script>
