<template>
  <mcs-sheet
    outlined
    no-padding
    class="relative overflow-hidden bg-gradient-successLight"
  >
    <div class="d-flex">
      <div class="pa-4">
        <img
          src="https://res.cloudinary.com/mycreativeshop/image/upload/v1732121692/public/design-review.png"
          width="150"
        />
      </div>
      <div class="pl-2 pr-4 pt-4 pb-4">
        <div class="d-flex align-center">
          <div class="body-1 mr-3 font-weight-bold">Design Review</div>
        </div>
        <div class="body-2 mt-2">
          Our print experts will review your design for alignment, bleed & image quality.
        </div>
      </div>
    </div>
    <div class="px-4 pb-4">
      <mcs-chip
        color="success"
        block
      >
        Included for free
      </mcs-chip>
    </div>
  </mcs-sheet>
</template>

<script>
export default {
  name: 'DesignReview',
  props: {}
};
</script>
