<template>
  <v-sheet
    :outlined="outlined"
    class="pa-6 relative overflow-hidden"
    :class="classes"
    @click="$emit('click', $event)"
  >
    <v-progress-linear
      v-if="loading"
      :indeterminate="loading"
      class="card-loader"
      :height="3"
      color="primary"
    />
    <slot name="header"></slot>
    <div
      :class="{
        'd-flex': $slots.prepend || $slots.append,
        'flex-column': true,
        'flex-md-row': true
      }"
    >
      <div
        v-if="$slots.prepend"
        class="mr-2 my-auto"
      >
        <slot name="prepend"> </slot>
      </div>
      <div class="my-auto overflow-hidden">
        <div class="d-flex flex-column flex-md-row">
          <div
            v-if="$slots['title-prepend']"
            class="mr-4 my-2 my-md-auto"
          >
            <slot name="title-prepend"></slot>
          </div>
          <div>
            <div
              v-if="title"
              :class="[titleSizeClass]"
            >
              <span v-html="title"></span>
              <span
                v-if="titleAppend"
                class="body-1 ml-2"
              >
                {{ titleAppend }}
              </span>
            </div>
            <div
              v-if="subtitle"
              class="mt-1"
              :class="subtitleSizeClass"
              v-html="subtitle"
            ></div>
          </div>
          <div
            v-if="$slots['title-append']"
            class="ml-auto my-2 my-md-auto"
          >
            <slot name="title-append"></slot>
          </div>
        </div>
        <div
          v-if="$slots.default"
          :class="{ 'mt-4': title || subtitle }"
        >
          <slot></slot>
        </div>
        <div
          v-if="$slots.actions"
          class="pt-4 d-flex"
        >
          <slot name="actions"></slot>
        </div>
      </div>
      <div
        v-if="$slots.append"
        class="ml-auto my-auto"
      >
        <slot name="append"> </slot>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'McsCard',

  props: {
    title: { type: String, default: '' },
    titleAppend: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    transparent: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    fillHeight: Boolean,
    loading: Boolean,
    // ********* THESE 2 PROPERTIES SHOULD RARELY BE USED. *********
    // they are here so we can create components that utilize mcs-card
    // but require a different sized text for the title & subtitle
    titleSizeClass: { type: String, default: 'text-h6' },
    subtitleSizeClass: { type: String, default: 'body-2' }
    // **************************************************************
  },

  data() {
    return {};
  },

  computed: {
    classes() {
      var list = [];
      if (!this.transparent && this.outlined) list.push('border-grey');
      if (this.transparent) list.push('bg-transparent');
      if (this.fillHeight) list.push('h-full');
      return list;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
</style>
