<template>
  <v-alert
    v-if="!hide"
    :color="color.fill"
    :dismissible="dismissible"
    :style="{ border: 'solid 1.5px ' + color.other + '!important' }"
  >
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex">
        <v-icon
          v-if="prependIcon"
          class="mr-3"
          small
          :color="iconColor"
        >
          {{ prependIcon }}
        </v-icon>
        <div
          v-if="boldText"
          class="font-weight-bold"
        >
          {{ boldText }}
        </div>
        <div v-if="text">
          <span
            v-if="boldText"
            class="mx-1 font-weight-bold"
          >
            -
          </span>
          <span v-html="text"></span>
        </div>

        <slot />
      </div>
      <template v-if="linkText">
        <router-link
          v-if="routeUrl"
          :to="routeUrl"
          class="font-weight-bold text-decoration-none flex-shrink-0 align-self-center ml-4 black--text"
        >
          {{ linkText }}
        </router-link>
        <a
          v-else-if="linkUrl"
          :href="linkUrl"
          :target="openLinkInNewWindow ? '_blank' : ''"
          class="font-weight-bold text-decoration-none flex-shrink-0 align-self-center ml-4 black--text"
        >
          {{ linkText }} >
        </a>
        <div
          v-else-if="linkText !== 'View Link '"
          class="font-weight-bold text-decoration-none flex-shrink-0 align-self-center ml-4 black--text hover-pointer"
          @click="$emit('click')"
        >
          {{ linkText }}
        </div>
      </template>
      <slot name="actions"></slot>
    </div>
    <template
      v-if="dismissible"
      #close="{ toggle }"
    >
      <v-icon
        small
        class="ml-4"
        @click="dismiss(toggle)"
      >
        mdi-close
      </v-icon>
    </template>
  </v-alert>
</template>

<script>
import { ALERT_TYPES, COLORS, ENUMS } from '@/utils/constants';

export default {
  name: 'McsAlert',

  props: {
    type: { type: String, default: 'alert' }, // valid types are: 'Error', 'Alert', 'Success', 'Help', 'Info'
    boldText: { type: String, default: '' },
    text: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    linkUrl: { type: String, default: '' },
    linkText: { type: String, default: 'View Link ' },
    routeUrl: { type: String, default: '' },
    openLinkInNewWindow: Boolean,
    dismissible: Boolean,
    dismissibleKey: { type: String, default: '' }
  },

  data() {
    return { hide: false };
  },

  computed: {
    hideAlertType() {
      if (this.dismissibleKey) {
        return ENUMS.LOCAL.HIDE_ALERT.replace('[type]', this.dismissibleKey);
      }
      return '';
    },
    iconColor() {
      switch (this.type) {
        case ALERT_TYPES.INFO:
          return COLORS.BLACK.HEX;
      }
      return this.color.other;
    },
    color() {
      var color = {
        fill: '',
        border: ''
      };
      switch (this.type) {
        case ALERT_TYPES.ALERT:
          color.fill = COLORS.ACCENT_LIGHT.NAME;
          color.other = COLORS.ACCENT.HEX;
          break;
        case ALERT_TYPES.SUCCESS:
          color.fill = COLORS.SUCCESS_LIGHT.NAME;
          color.other = COLORS.SUCCESS.HEX;
          break;
        case ALERT_TYPES.ERROR:
          color.fill = COLORS.ERROR_LIGHT.NAME;
          color.other = COLORS.ERROR.HEX;
          break;
        case ALERT_TYPES.HELP:
          color.fill = COLORS.PRIMARY_LIGHT.NAME;
          color.other = COLORS.PRIMARY.HEX;
          break;
        case ALERT_TYPES.INFO:
          color.fill = COLORS.GREY_LIGHT.NAME;
          color.other = COLORS.GREY.HEX;
          break;
      }
      return color;
    }
  },

  mounted() {
    this.hide = this.dismissible && this.hideAlertType ? this.getLocal(this.hideAlertType) : false;
  },

  methods: {
    dismiss(toggle) {
      toggle();
      this.$emit('dismiss');
      if (this.hideAlertType) {
        this.setLocal(this.hideAlertType, true);
      }
    }
  }
};
</script>

<style></style>
