<template>
  <div class="px-4">
    <mcs-sheet
      outlined
      class="successLight border-success"
    >
      <div>
        <div class="subtitle-2">Order Confirmation 🎉</div>
      </div>
      <div>
        Thank you for choosing us! We’ll get started right away and we promise to take great care of
        your prints.
      </div>
    </mcs-sheet>
    <mcs-card
      title="Order Summary"
      no-padding
      titleSizeClass="subtitle-2"
      class="d-flex flex-column justify-space-between mt-4"
    >
      <div>
        <div class="mt-4 d-flex justify-space-between">
          <div>{{ order.count }} print item<template v-if="order.count > 1">s</template></div>
          <span>{{ order.subTotal | currency }}</span>
        </div>
        <div
          v-if="order.discount"
          class="mt-1 d-flex justify-space-between"
        >
          <span>Discount</span>
          <span>-{{ order.discount | currency }}</span>
        </div>
        <div
          v-if="order.taxes > 0"
          class="mt-1 d-flex justify-space-between"
        >
          <span> Tax </span>
          <span>{{ order.taxes | currency }}</span>
        </div>
        <div class="mt-1 d-flex justify-space-between font-weight-bold">
          <span>Total</span>
          <span>{{ order.total | currency }}</span>
        </div>
      </div>
      <mcs-sheet
        outlined
        dense
        class="d-flex mt-4"
      >
        <v-icon class="mr-2 my-auto">mdi-receipt-text</v-icon>
        <span class="my-auto">Order #{{ order.orderId }}</span>
        <mcs-btn
          class="ml-auto px-1"
          text
          color="primary"
          @click="viewReceipt"
        >
          Review receipt &gt;
        </mcs-btn>
      </mcs-sheet>
    </mcs-card>
    <Portal :to="`drawerAppend-${drawerId}`">
      <v-divider />
      <v-row class="flex-grow-0 pa-2 ma-0 light">
        <v-col cols="12">
          <mcs-btn
            large
            block
            color="black"
            @click="goToPrintProducts"
          >
            View more print products &gt;
          </mcs-btn>
        </v-col>
      </v-row>
    </Portal>
  </div>
</template>

<script>
import { ROUTES } from '../../../utils/constants';

export default {
  name: 'PrintCheckoutConfirmation',

  props: {
    iframed: Boolean,
    order: {
      type: Object,
      default: () => {}
    },
    drawerId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    viewReceipt() {
      // refresh our collection of orders which is used to show/hide the 'Orders' link in the main nav
      this.$store.dispatch('orders/getAll');
      this.$router.push(ROUTES.ORDER_DETAIL.PATH + '/' + this.order.orderId);
    },
    goToPrintProducts() {
      this.$router.push(ROUTES.PRINT.PATH);
      this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
    }
  }
};
</script>
