<template>
  <div>
    <mcs-sheet
      outlined
      class="successLight border-success body-2"
    >
      <div class="d-flex justify-space-between align-center">
        <div class="font-weight-bold subtitle-2">Order Confirmation 🎉</div>
      </div>
      <div class="my-6">
        You've just unlocked unlimited access to everything MyCreativeShop has to offer. We're
        excited to have you here with us!
      </div>
      <div>Have questions? support@mycreativeshop.com</div>
      <div class="mt-5">Cheers,<br />MyCreativeShop Team</div>
    </mcs-sheet>
    <mcs-btn
      color="black"
      large
      block
      class="mt-8 mb-3"
      @click="close"
    >
      Continue
    </mcs-btn>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionCheckoutConfirmation',

  data() {
    return {};
  },

  methods: {
    close() {
      this.$store.commit('drawers/CLOSE_ALL');
    }
  }
};
</script>
