import { ENUMS, ROUTES } from '../constants';
import { getLocal, setLocal } from '../local';
export default {
  computed: {
    projectLinks() {
      var links = [
        {
          title: 'My designs',
          name: 'My designs',
          path: ROUTES.PROJECTS.PATH,
          route: ROUTES.PROJECTS.PATH,
          icon: 'mdi-view-dashboard-variant',
          exact: true
        }
      ];
      var teamLink = {
        title: 'Team designs',
        name: 'Team designs',
        path: ROUTES.TEAM_PROJECTS.PATH,
        route: ROUTES.TEAM_PROJECTS.PATH,
        hide: false
      };

      if (!this.$store.getters['user/hasCollectionTemplates']) teamLink.hide = true;
      if (this.$store.getters['user/isCollectionAdmin']) teamLink.hide = true;

      return links.filter((x) => !x.hide);
    }
  },
  methods: {
    setLocal(key, val) {
      setLocal(key, val);
    },
    getLocal(key, val) {
      return getLocal(key, val);
    },

    getCampaignIdeasLink(id) {
      if (!id) {
        id = ENUMS.SNAILBLAST.CAMPAIGN_TYPE.LIST_PURCHASE;
      }
      return ROUTES.CAMPAIGN_IDEAS.PATH.replace(':id', id);
    },
    getOrdersLink(type) {
      if (!type) {
        type = 'all';
      }
      return ROUTES.ADMIN_ORDERS.PATH.replace(':orderType', type);
    },
    setBrandColor() {
      var primaryColor = this.$store.getters['user/master_account'].primaryColor;
      console.log('primaryColor', primaryColor);
      if (primaryColor && this.$portalMode()) {
        this.$vuetify.theme.themes.light.brand = primaryColor;
      }
    }
  }
};
