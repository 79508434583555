<template>
  <Drawer
    :id="drawerId"
    :title="title"
  >
    <div class="px-4 pt-4">
      <mcs-alert
        v-if="blocked"
        type="error"
        prependIcon="mdi-lock"
        text="Inviting is blocked until you reactivate"
        linkText="Reactivate >"
        :routeUrl="subscriptionRoute"
      />
      <mcs-alert
        v-if="done && confirmation && confirmation.emailsNotAdded.length"
        :text="`${confirmation.emailsNotAdded.length} people couldn’t be added because they belong to another team`"
        prepend-icon="mdi-account-multiple"
        type="error"
      >
        <template #actions>
          <mcs-btn
            text
            color="black"
            class="px-0"
            @click="openBeacon"
          >
            Contact us
          </mcs-btn>
        </template>
      </mcs-alert>
      <div class="d-flex mb-4 align-center">
        <v-img
          src="https://res.cloudinary.com/mycreativeshop/image/upload/v1731508429/public/invite-person.png"
          max-width="80"
          contain
          class="mr-4"
        />
        <div>
          <div class="subtitle-1">
            {{ message }}
          </div>
          <div class="body-2">
            {{ done ? `We’re sending email invites` : 'Extend creative superpowers to anyone' }}
          </div>
        </div>
        <mcs-chip
          v-if="done"
          class="ml-auto"
          color="successLight"
        >
          Confirmation
        </mcs-chip>
      </div>
      <template v-if="done">
        <mcs-sheet class="greyLight mt-4 body-2">
          <div class="font-weight-bold">
            <v-icon
              class="mr-1"
              small
            >
              mdi-email
            </v-icon>
            Invitation emails
          </div>
          <div>
            An invitation email will be sent in less than 5 minutes. New people will be asked to
            create a password before they login. Once a password is created they will be able to use
            everything you’ve enabled for them.
          </div>
        </mcs-sheet>
        <template v-if="buttons.length">
          <div class="text-center body-1 mt-8 mb-6">
            <hr class="mb-n3" />
            <span class="white px-4">Manage Team</span>
          </div>
          <mcs-btn
            v-for="(button, i) in buttons"
            :key="i"
            color="black"
            large
            class="mt-4"
            block
            :to="button.to"
          >
            {{ button.text }}
          </mcs-btn>
        </template>
      </template>
      <v-form
        v-else
        ref="form"
        v-model="valid"
      >
        <template v-if="!blocked">
          <v-text-field
            v-for="(email, i) in emails"
            :key="i"
            v-model="emails[i]"
            :rules="[emails.length == 1 ? $rules.required : true, $rules.emailOrEmpty]"
            outlined
            placeholder="Enter email address..."
            label="Email address"
            hide-details="auto"
            class="mb-4"
            @keyup="updateEmailList(i)"
          ></v-text-field>
          <v-select
            v-if="collections.length > 1 && !createCollection"
            v-model="currentCollectionId"
            class="mb-4"
            :items="collections"
            label="Add to team"
            item-text="name"
            item-value="id"
            outlined
            hide-details="auto"
          />
        </template>
        <mcs-sheet class="greyLight body-2">
          <div class="font-weight-bold">
            <v-icon
              class="mr-1"
              small
              >mdi-eye</v-icon
            >
            What can they see?
          </div>
          <div>
            Only designs, logos, images and colors you add to your team area are shared with your
            people. Your billing info, personal designs and uploads are private to you.
          </div>
        </mcs-sheet>
      </v-form>
    </div>
    <Portal
      vif="!blocked"
      :to="`drawerAppend-${drawerId}`"
    >
      <div
        v-if="!done"
        class="pa-4"
      >
        <v-checkbox
          v-if="paymentInfo && newPrice != paymentInfo.price"
          v-model="acknowledged"
          class="mt-0 pt-0"
        >
          <template #label>
            <span class="body-2">
              I acknowledge my subscription will increase to {{ plan.currencySymbol
              }}{{ newPrice }} on
              {{ paymentInfo.date | normalDate }}
              ({{ plan.currencySymbol }}{{ plan.perUserPrice }} additional per person)
            </span>
          </template>
        </v-checkbox>
        <mcs-btn
          large
          color="black"
          block
          :loading="saving"
          :disabled="paymentInfo && !acknowledged"
          @click="save"
        >
          Send Invite
        </mcs-btn>
      </div>
    </Portal>
  </Drawer>
</template>

<script>
import Drawer from '@/components/layout/Drawer';
import { APP_EVENTS, DRAWERS, ENUMS } from '@/utils/constants';
import collections from '@/utils/mixins/collections';
import { uniq } from 'lodash';
import { mapGetters } from 'vuex';
import { ANALYTICS_EVENTS, ROUTES } from '../../utils/constants';

export default {
  name: 'AddUsersDrawer',

  components: {
    Drawer
  },

  mixins: [collections],

  data() {
    return {
      drawerId: DRAWERS.ADD_PEOPLE,
      valid: false,
      saving: false,
      currentCollectionId: null,
      emails: [''],
      acknowledged: false,
      done: false,
      confirmation: {},
      paymentInfo: null,
      createCollection: false,
      subscriptionRoute: ROUTES.SUBSCRIPTION.PATH
    };
  },

  computed: {
    title() {
      return this.done ? 'Complete' : 'Add People';
    },
    blocked() {
      return !this.plan.subscriptionIsCurrent && this.isCollectionAdmin;
    },
    newPrice() {
      if (!this.paymentInfo) {
        return 0;
      }
      return (
        this.paymentInfo.price +
        this.validatedEmails.length * this.plan.perUserPrice
      ).toFixed(2);
    },
    validatedEmails() {
      return uniq(this.emails).filter((x) => !!x);
    },
    message() {
      var msg = 'Invite your people';
      if (this.done) {
        msg = this.confirmation.emailsAdded.length;
        if (this.confirmation.emailsAdded.length > 1) {
          msg += ' people';
        } else {
          msg += ' person';
        }
        msg += ' added';
      }
      return msg;
    },
    buttons() {
      if (!this.currentCollectionId) {
        return [];
      }
      return [
        {
          text: 'Add templates',
          to: this.getCollectionsDetailPath(
            ENUMS.ASSET_TYPES.TEMPLATES,
            null,
            this.currentCollectionId
          )
        },
        {
          text: 'Add logos & images',
          to: this.getCollectionsDetailPath(
            ENUMS.ASSET_TYPES.IMAGES,
            null,
            this.currentCollectionId
          )
        },
        {
          text: 'Add brand colors',
          to: this.getCollectionsDetailPath(
            ENUMS.ASSET_TYPES.COLORS,
            null,
            this.currentCollectionId
          )
        }
      ];
    },
    ...mapGetters({
      initialData: 'drawers/data',
      top_drawer: 'drawers/topDrawer',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      collections: 'admin/collections/list',
      isCollectionAdmin: 'user/isCollectionAdmin',
      profile: 'user/profile',
      plan: 'user/plan',
      master_account: 'user/master_account'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.drawerId === this.top_drawer?.id) {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.TEAMS.NAME,
          ANALYTICS_EVENTS.TEAMS.ACTIONS.INVITE_DRAWER_OPENED
        );
        this.emails = [''];
        this.confirmation = { emailsAdded: [], emailsNotAdded: [] };
        const initial = this.initialData(this.drawerId) || {};
        this.currentCollectionId =
          initial.collectionId || (this.collections.length === 1 ? this.collections[0].id : null);
        this.createCollection = initial.createCollection || false;
        if (this.createCollection) {
          this.currentCollectionId = null;
        }
        this.done = false;
        this.acknowledged = false;
        this.loadPaymentInfo();
      }
    }
  },

  methods: {
    loadPaymentInfo() {
      this.paymentInfo = null;
      if (this.master_account.externalBilling) {
        return;
      }
      this.$api
        .get('user/getNextPayment')
        .then((data) => {
          this.paymentInfo = data.data;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    openBeacon() {
      this.$eventBus.$emit(APP_EVENTS.OPEN_HELPSCOUT, { ask: true });
    },
    cancel() {
      this.$store.commit('drawers/CLOSE_ALL');
    },
    save() {
      const emails = this.validatedEmails;
      if (this.$refs.form.validate() && emails.length) {
        this.saving = true;
        this.$store
          .dispatch('user/bulkAddUsers', {
            emails: emails,
            collectionId: this.currentCollectionId,
            createCollection: this.createCollection
          })
          .then((data) => {
            if (this.$refs.form) {
              this.$refs.form.reset();
            }
            if (this.createCollection) {
              this.$store.dispatch('config/getAppData').then(() => {
                this.$store.dispatch('subscription/getDetail');
              });
            }
            this.$eventBus.$emit(APP_EVENTS.REFRESH_PEOPLE);
            this.confirmation = data;
            this.currentCollectionId = data.collectionId;
            this.done = true;
            if (this.confirmation.emailsNotAdded.length) {
              this.$mixpanel.trackEvent(
                ANALYTICS_EVENTS.TEAMS.NAME,
                ANALYTICS_EVENTS.TEAMS.ACTIONS.INVITE_ERROR,
                { message: `emails not added: ${this.confirmation.emailsNotAdded.join(', ')}` }
              );
            }
          })
          .catch((error) => {
            this.logError(error, 'An error occurred while inviting people.');
            this.$mixpanel.trackEvent(
              ANALYTICS_EVENTS.TEAMS.NAME,
              ANALYTICS_EVENTS.TEAMS.ACTIONS.INVITE_ERROR,
              { message: error.message }
            );
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    updateEmailList(index) {
      const current = this.emails[index];
      if (current.length > 0) {
        // add empty if one doesn't already exist
        let found = false;
        for (let i = 0; i < this.emails.length; i++) {
          if (!this.emails[i].length) {
            found = true;
          }
        }
        if (!found) {
          this.emails.push('');
          this.acknowledged = false;
        }
      } else {
        // remove all non-empty
        for (let i = 0; i < this.emails.length; i++) {
          if (i !== index && !this.emails[i].length) {
            this.emails.splice(i, 1);
            this.acknowledged = false;
            break;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__append-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
