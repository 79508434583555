<template>
  <div
    v-if="render"
    class="caption font-weight-medium"
  >
    You will be charged in {{ selectedPlan.currency.code }}&nbsp;|&nbsp;
    <v-menu
      top
      offset-y
      :close-on-content-click="true"
    >
      <template #activator="{ on }">
        <span
          class="font-weight-medium primary--text hover-pointer"
          v-on="on"
        >
          Change
        </span>
      </template>
      <div>
        <v-list
          dense
          class="py-0"
        >
          <v-list-item
            v-for="(currency, index) in currencies"
            :key="index"
            @click="selectCurrency(currency)"
          >
            <v-list-item-content>
              <div>
                {{ currency.code
                }}<span
                  class="ml-1"
                  v-html="currency.hex"
                />
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'MultiCurrencyToggle',

  components: {},

  data() {
    return {};
  },

  computed: {
    render() {
      return this.$store.getters['subscription/enableMultiCurrency'];
    },

    selectedPlan() {
      return this.$store.getters['subscription/selectedPlan'];
    },

    currencies() {
      return this.$store.getters['subscription/currencies'];
    }
  },

  methods: {
    selectCurrency(currency) {
      var isMonthly = this.selectedPlan.isMonthly;
      var planGroup = this.$store.state.subscription.allPlans.find(
        (x) => x.currency.code === currency.code
      );
      var plan = isMonthly ? planGroup.monthlyPlan : planGroup.annualPlan;
      this.$store.commit('subscription/SET_SELECTED_PLAN', plan);
    }
  }
};
</script>

<style scoped></style>
