<template>
  <v-container
    v-if="render"
    class="px-8 my-3"
  >
    <div
      style="background-color: #d4eefb"
      class="d-flex align-center justify-space-between px-8 py-5 rounded-xl"
      :class="{ 'flex-column': $vuetify.breakpoint.mdAndDown }"
    >
      <div
        :class="{
          'text-center text-h4': $vuetify.breakpoint.mdAndDown,
          'pl-10 text-h4': $vuetify.breakpoint.lgAndUp,
          'ml-10': $vuetify.breakpoint.xl
        }"
      >
        <div class="color-mp font-weight-regular">Seasonal Savings</div>
        <div class="color-mp font-weight-medium">20% Off</div>
        <div class="color-mp font-weight-medium">All Print Orders</div>
        <div class="color-mp font-weight-regular">Until the End of the Year!</div>
      </div>

      <img
        src="https://res.cloudinary.com/mycreativeshop/image/upload/f_auto/v1734118150/public/mp_holidays_2024"
        style="max-height: 220px; max-width: 100%"
        class="d-flex"
        :class="$vuetify.breakpoint.mdAndUp ? 'justify-self-end' : 'mx-auto mt-6'"
      />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PromoBanner',

  data() {
    return {};
  },

  computed: {
    render() {
      return this.$store.getters['user/isMissionPrint'];
    }
  },

  methods: {}
};
</script>

<style scoped>
.color-mp {
  color: #ec008c;
}
.justify-self-end {
  justify-self: end;
}
</style>
