<template>
  <v-switch
    v-if="allowPlanToggle"
    :value="selectedPlan.isMonthly"
    :input-value="selectedPlan.isMonthly"
    hide-details
    @change="toggle"
  >
    <template #label>
      <div class="body-2 black--text">
        <span>{{ selectedPlanInterval }}</span>
        <span class="primary--text font-weight-bold ml-1">
          <template v-if="selectedPlan.isAnnual">
            Save {{ annualSavings.percent }}% over monthly!
          </template>
          <template v-else-if="enableFirstMonthDiscount">
            Special {{ selectedPlan.firstMonthCostFormatted }} first month
          </template>
        </span>
      </div>
    </template>
  </v-switch>
</template>

<script>
import { mapGetters } from 'vuex';
import copyObject from '../../utils/copyObject';

export default {
  name: 'IntervalToggle',

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      monthlyPlan: 'subscription/monthlyPlan',
      annualPlan: 'subscription/annualPlan',
      selectedPlan: 'subscription/selectedPlan',
      enableFirstMonthDiscount: 'subscription/enableFirstMonthDiscount',
      allowPlanToggle: 'subscription/allowPlanToggle',
      selectedPlanInterval: 'subscription/selectedPlanInterval',
      annualSavings: 'subscription/annualSavings'
    })
  },

  methods: {
    toggle() {
      var plan = this.selectedPlan.isMonthly
        ? copyObject(this.annualPlan)
        : copyObject(this.monthlyPlan);
      this.$store.commit('subscription/SET_SELECTED_PLAN', plan);
    }
  }
};
</script>

<style></style>
