<template>
  <mcs-sheet
    no-padding
    show-overflow
  >
    <div class="d-flex flex-column">
      <div class="relative">
        <div class="d-flex align-center pt-4 px-4 pb-4">
          <v-sheet class="greyLight pa-2">
            <v-img
              :src="project.previewUrl"
              max-width="90"
              max-height="75"
              contain
            />
          </v-sheet>
          <div class="ml-4 overflow-hidden">
            <div class="font-weight-bold body-1 text-truncate">
              {{ projectName || 'Unnamed project' }}
            </div>
            <div class="body-2">
              {{ project.coreProductName }} <br />
              <span>Modified: {{ project.modifiedDate | shortDateWithTime }}</span>
            </div>

            <div v-if="requireProjectApproval">
              <v-divider class="pt-2" />
              <div
                id="projectPrintApproval"
                class="d-flex align-center justify-space-between px-4 body-2 pb-2"
              >
                <div v-if="!project.printApproved">
                  <v-icon color="error"> mdi-alert-circle-check-outline </v-icon>
                  <span class="font-weight-bold ml-2">Approval Required</span>
                </div>
                <div v-else>
                  <span class="font-weight-bold mr-2">Design Approved</span>
                  <v-icon color="success"> mdi-check-circle-outline </v-icon>
                </div>
                <mcs-btn
                  v-if="iframed"
                  :color="project.printApproved ? 'success' : 'primary'"
                  class="px-5"
                  small
                  @click="iframeReview"
                >
                  Review
                </mcs-btn>
                <EditorButton
                  v-else
                  :forProjectId="project.id"
                  openInDrawer
                  :color="project.printApproved ? 'success' : 'primary'"
                  buttonText="Review"
                  appendToUrl="&printApproval=true"
                />
              </div>
            </div>
            <div v-else-if="!iframed">
              <div class="d-flex align-center mt-2">
                <mcs-chip
                  outlined
                  color="white"
                  append-icon="mdi-open-in-new"
                  small
                  class="px-2 mr-2"
                  @click="showProof"
                >
                  Preview
                </mcs-chip>
                <EditorButton
                  v-if="!edit_item.isReorder"
                  :forProjectId="project.id"
                  chip
                  showEditIcon
                  :openInDrawer="project.isPrintUpload"
                  color="white"
                />
                <mcs-chip
                  outlined
                  color="white"
                  append-icon="mdi-close"
                  small
                  class="px-2 ml-2"
                  @click="removeProject"
                >
                  Remove
                </mcs-chip>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="edit_item.isReorder"
          class="accent pa-2 body-2 font-weight-bold d-flex-hide"
        >
          This is a re-order so editing the design is disabled.
          <Tooltip
            :maxWidth="370"
            align="bottom"
            offset="y"
            content="This is an exact re-order of the artwork that was in your previous order. If you need to
          change your artwork, please click on the 'My designs' menu, find the project you want to
          make changes to, and then order prints of that project instead of placing a re-order."
            class="ml-2"
          />
        </div>
      </div>
    </div>
    <div
      v-if="page_selections.length"
      class="d-flex align-center px-4 mt-5"
    >
      <v-select
        v-for="(page, index) in page_selections"
        :key="index"
        :value="page.pageId"
        return-object
        outlined
        :label="'Print on ' + getPageOrSideName(index)"
        :items="page_options(page.number)"
        hide-details
        item-text="name"
        item-value="pageId"
        class="mb-4"
        style="width: 50%"
        :class="{ 'mr-2': index % 2 === 0, 'ml-2': index % 2 === 1 }"
        :menu-props="{ maxHeight: '100%' }"
        @input="pageSelected($event, page)"
      >
        <template #selection="{ item }">
          <div
            v-if="item.pageId === 'blank'"
            class="body-1 font-weight-bold"
          >
            Leave blank
          </div>
          <img
            v-else
            :src="item.previewUrl"
            class="page-preview"
          />
        </template>
        <template #item="{ active, item, attrs, on }">
          <div
            v-bind="attrs"
            class="d-flex align-center justify-center pa-2 page-select-preview rounded-lg w-full"
            v-on="on"
          >
            <mcs-sheet
              v-if="item.pageId === 'blank'"
              dense
              class="body-2 font-weight-bold w-full text-center"
            >
              Not Printed / Blank
            </mcs-sheet>
            <img
              v-else
              :src="item.previewUrl"
              class="page-preview"
              style="max-height: 150px; max-width: 180px"
            />
          </div>
        </template>
      </v-select>
    </div>
  </mcs-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import { APP_EVENTS } from '../../../utils/constants';
import copyObject from '../../../utils/copyObject';
import EditorButton from '../../utilities/EditorButton';
import Tooltip from '../../utilities/Tooltip';

export default {
  name: 'PrintProjectDetails',

  components: {
    EditorButton,
    Tooltip
  },

  props: {
    iframed: Boolean
  },

  data() {
    return {};
  },

  computed: {
    requireProjectApproval() {
      return this.$store.state.printcart.requireProjectApproval;
    },

    projectName() {
      return this.project.name.replace('snapshot of ', '');
    },

    ...mapGetters({
      edit_item: 'printcart/edit_item',
      project: 'printcart/project',
      page_options: 'printcart/page_options',
      page_selections: 'printcart/page_selections',
      material_quantity: 'printcart/material_quantity'
    })
  },

  methods: {
    removeProject() {
      var obj = copyObject(this.edit_item);
      obj.project = {};
      this.$emit('doEditItem', obj);
    },

    showProof() {
      this.$eventBus.$emit(APP_EVENTS.SHOW_PROOF_OVERLAY, {
        show: true,
        projectId: this.project.id
      });
    },

    pageSelected(selection, page) {
      var pages = copyObject(this.edit_item.pageSelections);
      pages.forEach((x) => {
        if (x.number === page.number) {
          x.pageId = selection.pageId;
          x.previewUrl = selection.previewUrl;
        }
      });
      this.$store.commit('printcart/SET_EDIT_ITEM_PAGE_SELECTIONS', pages);
      var colorSpecChanged = this.setColorSpec();
      if (colorSpecChanged) this.$emit('refreshPrice');
    },

    setColorSpec() {
      if (this.edit_item.pageSelections.length) {
        var colorSpecs = this.material_quantity.colorSpecs || [];
        var printedSideCount =
          this.edit_item.pageSelections.filter((x) => x.pageId !== 'blank') || [];
        var currentColorSpecId = this.edit_item.colorSpecId;
        var colorSpec;
        if (printedSideCount.length === 1) {
          colorSpec = colorSpecs.find((x) => x.name.includes('4/0'));
        } else {
          colorSpec = colorSpecs.find((x) => x.name.includes('4/4'));
        }
        if (!colorSpec) colorSpec = colorSpecs.find((x) => x.name === '4/0');
        var colorSpecId = colorSpec?.id || colorSpecs[0].id;
        var colorSpecChanged = currentColorSpecId !== colorSpecId;
        if (colorSpecChanged) {
          this.edit_item.colorSpecId = colorSpecId;
        }
        console.log('color spec is: ', this.edit_item.colorSpecId);
        return colorSpecChanged;
      }
    },

    getPageOrSideName(pageIndex) {
      if (this.project.pageAndSideNames?.length) {
        if (pageIndex <= this.edit_item.project.pageAndSideNames.length - 1) {
          return `${this.edit_item.project.pageAndSideNames[pageIndex]}`;
        } else {
          return `Page ${pageIndex + 1}`;
        }
      }
    },

    iframeReview() {
      window.parent.postMessage({ type: 'close-iframed-drawers' }, '*');
      window.parent.postMessage({ type: 'open-print-approval' }, '*');
    }
  }
};
</script>

<style scoped>
.preview-background {
  background: transparent linear-gradient(180deg, #ffffff 0%, #ececefd9 100%) 0% 0% no-repeat
    padding-box;
}
.page-preview {
  border: solid 1.5px;
  border-radius: 5px;
  max-width: 100px;
  max-height: 30px;
}
</style>
