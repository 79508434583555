<template>
  <Drawer
    :id="drawerId"
    title="Confirm Cancelation"
    padded
  >
    <div>
      <div>Are you sure you want to cancel?</div>
      <mcs-alert
        type="error"
        prependIcon="mdi-cancel"
        class="mt-4"
      >
        <div>
          By canceling you will no longer have unlimited storage, which means your
          <span class="font-weight-bold">designs are at risk of being deleted</span> in our next
          storage audit.
        </div>
      </mcs-alert>
      <v-row class="mt-3">
        <v-col cols="12">
          <v-select
            v-model="reason"
            outlined
            label="Why are you canceling?"
            placeholder="Why are you canceling?"
            persistent-placeholder
            :items="reasons"
            item-text="value"
            item-value="key"
            hide-details="auto"
            return-object
            :rules="[$rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-8" />
      <v-row class="mt-5">
        <v-col cols="6">
          <mcs-btn
            color="black"
            block
            @click="close"
          >
            No, don't cancel
          </mcs-btn>
        </v-col>
        <v-col cols="6">
          <mcs-btn
            color="error"
            block
            :loading="processing"
            :disabled="!reason"
            @click="doCancel"
          >
            Yes, cancel
          </mcs-btn>
        </v-col>
      </v-row>
      <mcs-alert
        v-show="error"
        type="error"
        class="mt-3"
        :text="error"
      />
    </div>
  </Drawer>
</template>

<script>
import Drawer from '../../components/layout/Drawer';
import { DRAWERS } from '../../utils/constants';

export default {
  name: 'CancelSubscriptionDrawer',

  components: {
    Drawer
  },

  data() {
    return {
      drawerId: DRAWERS.CANCEL_SUBSCRIPTION_DRAWER,
      error: '',
      processing: false,
      reason: null
    };
  },

  computed: {
    reasons() {
      return this.$store.state.lookups.subscriptionCancelReasons;
    }
  },

  watch: {},

  methods: {
    close() {
      this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
    },

    doCancel() {
      this.processing = true;
      this.$store
        .dispatch('subscription/cancel', this.reason.key)
        .then(async (result) => {
          if (result.success) {
            await this.$store.dispatch('subscription/getDetail');
            this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
            this.$root.$snackbarSuccess('Subscription Canceled');
          } else {
            this.error = result.message;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style></style>
