<template>
  <mcs-sheet
    outlined
    :dense="dense"
    :small-radius="dense"
    class="relative overflow-hidden"
    @click="$emit('click', $event)"
  >
    <div
      class="d-flex"
      :class="{ 'ml-4': showBar }"
    >
      <v-icon
        v-if="icon"
        :size="iconSize"
        class="mr-4"
      >
        {{ icon }}
      </v-icon>
      <template v-if="title || subtitle">
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex justify-space-between align-center">
            <div :class="titleClasses">
              {{ title }}
            </div>
            <slot name="actions" />
          </div>
          <div
            v-if="subtitle"
            :class="subtitleClasses"
          >
            {{ subtitle }}
          </div>
        </div>
      </template>
      <template v-else>
        <slot />
      </template>
      <div
        v-if="$slots.append"
        class="my-auto"
      >
        <slot name="append" />
      </div>
    </div>
    <div
      v-if="showBar"
      class="bar"
      :style="{ 'background-color': barColor, 'border-color': barBorderColor }"
    />
  </mcs-sheet>
</template>

<script>
import { COLORS } from '../../utils/constants';

export default {
  name: 'McsActionCard',

  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    showBar: Boolean,
    dense: Boolean,
    barColor: { type: String, default: COLORS.SUCCESS_LIGHT.HEX }
  },

  data() {
    return {
      barBorderColor: COLORS.GREY.HEX
    };
  },

  computed: {
    iconSize() {
      if (this.dense) return 32;
      if (this.title && this.subtitle) return 40;
      return 20;
    },
    titleClasses() {
      const list = ['font-weight-semibold'];
      list.push(this.dense ? 'body-1' : 'subtitle-1');
      return list;
    },
    subtitleClasses() {
      const list = ['body-2'];
      if (!this.dense) {
        list.push('mt-2');
      }
      return list;
    }
  }
};
</script>

<style scoped>
.bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16px;
  border-right: solid 1.5px;
}
</style>
