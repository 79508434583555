<template>
  <v-app-bar
    app
    flat
    :class="{ 'block-loading': blockLoading }"
    class="greyLight"
  >
    <v-app-bar-nav-icon
      v-show="$vuetify.breakpoint.mobile"
      @click="showNav"
    />

    <v-container>
      <div class="d-flex align-center pl-6">
        <v-breadcrumbs
          v-if="!$vuetify.breakpoint.mobile"
          :items="breadcrumbs"
          class="pl-0"
        >
          <template #item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              :disabled="false"
              exact-path
              class="font-weight-medium"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
          <template #divider>
            <span>></span>
          </template>
        </v-breadcrumbs>

        <v-spacer />

        <NewDesignButton />
        <CartButton />
        <Avatar
          :size="32"
          :borderWidth="2"
          :link="profileLink"
        />
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { APP_EVENTS, ROUTES } from '../../utils/constants';
import CartButton from '../header/CartButton';
import NewDesignButton from '../header/NewDesignButton';
import Avatar from '../profile/Avatar';

export default {
  name: 'Header',

  components: {
    NewDesignButton,
    CartButton,
    Avatar
  },

  mixins: [],

  data() {
    return {
      profileLink: ROUTES.PROFILE.PATH
    };
  },

  computed: {
    routeName() {
      return this.$store.state.config.route.meta?.name;
    },

    blockLoading() {
      return this.$store.state.config.blockUILoading;
    },

    breadcrumbs() {
      var crumbs = [
        {
          text: ROUTES.DASHBOARD.DISPLAY,
          to: { name: ROUTES.DASHBOARD.NAME }
        }
      ];
      var routeCrumbs = this.$route.meta?.breadcrumbs || [];
      routeCrumbs.forEach((x) => {
        crumbs.push({
          text: x.DISPLAY,
          to: { name: x.NAME }
        });
      });
      if (!crumbs.find((x) => x.to?.name === this.$route.name)) {
        let text = this.$route.meta?.name;
        if (
          this.$route.name === ROUTES.ADMIN_COLLECTIONS_DETAIL_TYPE.NAME ||
          this.$route.name === ROUTES.ADMIN_COLLECTIONS_DETAIL_TYPE_CHILD.NAME
        ) {
          text = capitalizeFirstLetter(this.$route.params.typeId);
        }
        crumbs.push({
          text: text,
          to: { name: this.$route.name }
        });
      }
      return crumbs;
    }
  },

  methods: {
    showNav() {
      this.$eventBus.$emit(APP_EVENTS.SET_NAV_VISIBILITY, true);
    }
  }
};
</script>

<style scoped>
>>> .v-breadcrumbs li {
  padding: 0 3px !important;
}
>>> .v-breadcrumbs__item {
  color: black;
}
</style>
