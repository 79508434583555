<template>
  <Drawer
    :id="drawerId"
    :title="title"
    preloadContent
    height="75%"
    bottom
    closeGoesBack
    hideBack
  >
    <template #cart>
      <mcs-btn
        v-if="!addingAddress"
        text
        small
        color="primary"
        @click="manageAddresses"
      >
        Manage addresses
      </mcs-btn>
    </template>
    <div class="d-flex-hide flex-column justify-space-between h-full">
      <div class="pa-4 h-full">
        <v-form
          ref="form_delivery"
          v-model="valid"
        >
          <v-row class="mt-2">
            <v-col
              v-if="addressItems"
              cols="12"
            >
              <v-select
                v-model="addressId"
                outlined
                label="Address"
                persistent-placeholder
                :placeholder="addressId ? 'Address' : 'Select an address'"
                :items="addressItems"
                item-text="display"
                item-value="id"
                hide-details
                :rules="addressId == 0 ? [] : [$rules.required]"
                @input="changeAddress"
              />
            </v-col>
            <v-col
              v-if="addingAddress"
              cols="12"
            >
              <AddressForm
                ref="addAddress"
                @save="setAddress"
              />
            </v-col>
            <v-col
              v-else-if="addressId"
              cols="12"
            >
              <div
                v-if="loadingDelivery || validating"
                class="text-center"
              >
                <v-progress-circular
                  :size="50"
                  :width="2"
                  color="primary"
                  indeterminate
                  class="d-flex mx-auto my-3"
                />
                <div class="body-2 mt-3 font-italic">
                  Getting real-time delivery dates...please wait.
                </div>
              </div>
              <v-row v-else-if="delivery_items.length">
                <v-col
                  v-for="(item, index) in delivery_items"
                  :key="index"
                  cols="12"
                >
                  <mcs-sheet
                    v-ripple
                    outlined
                    dense
                    small-radius
                    class="hover-pointer"
                    @click="setDelivery(item)"
                  >
                    <v-row dense>
                      <v-col
                        cols="auto"
                        class="my-auto"
                      >
                        <v-icon
                          small
                          class="mr-2"
                        >
                          {{
                            item.days == delivery.days ? 'mdi-circle-slice-8' : 'mdi-circle-outline'
                          }}
                        </v-icon>
                      </v-col>
                      <v-col cols="auto">
                        <div class="body-1 font-weight-bold pt-1">
                          {{ item.earliestDate | regularDateNoYear }} -
                          {{ item.latestDate | regularDateNoYear }}
                        </div>
                        <div class="body-2 pb-1">Expected delivery date</div>
                      </v-col>
                      <v-spacer />
                      <v-col
                        cols="auto"
                        class="my-auto"
                      >
                        + {{ item.price | currency }}
                      </v-col>
                    </v-row>
                  </mcs-sheet>
                </v-col>
              </v-row>
              <AddressForm
                v-else-if="showCandidates"
                :id="addressId"
                hide-back
                @save="setAddress"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <template #append>
      <div class="pa-4">
        <mcs-btn
          large
          :color="disableSave ? 'grey' : 'black'"
          block
          :disabled="disableSave"
          :loading="saving"
          @click="save"
        >
          {{ saveText }}
        </mcs-btn>
      </div>
    </template>
  </Drawer>
</template>

<script>
import Drawer from '@/components/layout/Drawer';
import AddressForm from '@/components/print/checkout/addresses/Form';
import { ANALYTICS_EVENTS, DRAWERS } from '@/utils/constants';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'PrintCheckoutShippingDrawer',

  components: {
    Drawer,
    AddressForm
  },

  props: {
    iframed: Boolean
  },

  data() {
    return {
      saving: false,
      valid: true,
      loadingDelivery: false,
      validating: false,
      drawerId: DRAWERS.PRINT_CHECKOUT_SHIPPING,
      delivery: {},
      addressId: null,
      preview: false,
      showCandidates: false
    };
  },

  computed: {
    title() {
      if (this.addingAddress) {
        return 'Shipping Address';
      }
      return 'Shipping';
    },

    saveText() {
      return 'Save shipping';
    },

    addingAddress() {
      return this.addressId === 0;
    },

    addressItems() {
      return [{ id: 0, display: 'Add New Address' }].concat(
        this.addresses.map((x) => ({
          id: x.id,
          display: `${x.firstName} ${x.lastName} - ${x.line1} ${x.city}, ${this.state_abbr(
            x.stateProvinceId
          )} ${x.postalCode}`
        }))
      );
    },

    address() {
      return this.addresses.find((x) => x.id === this.addressId);
    },

    disableSave() {
      if (this.loadingDelivery || this.validating || !this.valid) return true;
      return false;
    },

    ...mapGetters({
      material_quantities: 'printcart/material_quantities',
      material_quantity: 'printcart/material_quantity',
      option_groups: 'printcart/option_groups',
      delivery_items: 'printcart/delivery',
      addresses: 'user/addresses',
      candidates: 'user/candidates',
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      edit_item: 'printcart/edit_item',
      state_abbr: 'lookups/state_abbr'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        this.preview = this.initialData.preview;
        this.addressId = this.edit_item.addressId || undefined;
        this.delivery = this.edit_item.delivery || {};
        if (!this.addressId) {
          if (this.addresses.length === 0) {
            this.addressId = null;
          } else if (this.addresses.length === 1) {
            this.addressId = this.addresses[0].id;
            this.changeAddress();
          } else {
            const defaultAddress = this.addresses.find((x) => x.isDefault);
            if (defaultAddress) {
              this.addressId = defaultAddress.id;
              this.changeAddress();
            }
          }
        } else {
          this.changeAddress();
        }
      }
    }
  },

  mounted() {},

  methods: {
    getRequest() {
      return Object.assign(cloneDeep(this.edit_item), {
        addressId: this.addressId,
        delivery: this.delivery
      });
    },
    getDelivery() {
      if (!this.addressId) {
        return;
      }
      this.loadingDelivery = true;
      this.$store
        .dispatch('printcart/getDelivery', { item: this.getRequest(), preview: this.preview })
        .then(() => {
          let item = this.delivery_items.find((x) => x.days === this.edit_item.delivery?.days);
          if (!item) {
            item = this.delivery_items[0];
          }
          this.setDelivery(item);
        })
        .catch((error) => {
          this.$mixpanel.trackEvent(
            ANALYTICS_EVENTS.PRINT.NAME,
            ANALYTICS_EVENTS.PRINT.ACTIONS.CART_ERROR,
            { message: 'Error getting delivery. ' + error.message }
          );
          console.log(error);
          this.$root.$alert('Error Occurred', 'An error occurred while loading delivery.');
        })
        .finally(() => {
          this.loadingDelivery = false;
        });
    },

    setDelivery(item) {
      this.delivery = item;
    },

    async changeAddress() {
      await this.$store.dispatch('printcart/clearDeliveryOptions');
      await this.$store.dispatch('user/clearCandidates');
      if (this.address) {
        if (this.address.validated == null) {
          this.validateAddress();
        } else {
          this.getDelivery();
        }
      }
    },

    validateAddress() {
      this.validating = true;
      this.$store
        .dispatch('user/validateAndSaveAddress', this.address)
        .then((data) => {
          this.showCandidates = data.candidates && data.candidates.length;
          if (!this.showCandidates) {
            this.getDelivery();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$root.$alert('Error Occurred', 'An error occurred while validating an address.');
        })
        .finally(() => {
          this.validating = false;
        });
    },

    setAddress(addressId) {
      this.addressId = addressId;
      this.saving = false;
      this.$nextTick(() => {
        this.getDelivery();
      });
    },

    save() {
      this.saving = true;
      if (this.addingAddress) {
        this.$refs.addAddress.save();
      } else {
        const editItem = cloneDeep(this.edit_item);
        editItem.addressId = this.addressId;
        editItem.delivery = this.delivery;
        editItem.delivery.valid = true;

        this.$store.dispatch('printcart/setEditItem', editItem);

        this.saving = false;
        this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
      }
    },

    saveAddress(address) {
      const addressToSave = cloneDeep(this.address);
      if (address) {
        addressToSave.line1 = address.line1;
        addressToSave.line2 = address.line2;
        addressToSave.city = address.city;
        addressToSave.stateProvinceId = address.stateProvinceId;
        addressToSave.postalCode = address.postalCode;
        addressToSave.countryId = address.countryId;
        addressToSave.validated = true;
      } else {
        addressToSave.validated = false;
      }
      this.saving = true;
      this.$store
        .dispatch('user/saveAddress', addressToSave)
        .then((id) => {
          this.getDelivery();
        })
        .catch((error) => {
          console.log(error);
          this.$root.$alert('Error Occurred', 'An error occurred while saving an address.');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    manageAddresses() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.MANAGE_ADDRESSES,
        data: { backText: '< Back to shipping' }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.MANAGE_ADDRESSES);
    }
  }
};
</script>

<style scoped></style>
