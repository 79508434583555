const state = {
  recent: [],
  designed: [],
  uploaded: [],
  selected: null,
  loadedAt: null
};

const getters = {
  all: (state, getters) => {
    var all = getters.designed.concat(getters.uploaded).sort((a, b) => {
      return new Date(b.modifiedDate) - new Date(a.modifiedDate);
    });
    return all;
  },
  designed: (state) => {
    return state.designed || [];
  },
  uploaded: (state) => {
    return state.uploaded || [];
  },
  project: (state, getters) => (id) => {
    return getters.all.find((x) => x.id === id) || {};
  },
  selected: (state) => {
    return state.selected || { editorAccess: {} };
  }
};

const actions = {
  getRecent({ commit }, count) {
    return new Promise((resolve, reject) => {
      var payload = {
        count: count
      };
      this._vm.$api
        .post('project/getRecent', payload)
        .then((response) => {
          commit('SET_RECENT', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDesigns({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('project/getAll')
        .then((response) => {
          commit('SET_DESIGNS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUploads({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('project/getUploads')
        .then((response) => {
          commit('SET_UPLOADS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDetail({ commit, rootState }, id) {
    commit('SET_SELECTED', null);
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`project/detail/${id}`)
        .then((response) => {
          commit('SET_SELECTED', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  rename({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('project/rename', payload)
        .then((response) => {
          commit('SET_NAME', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  duplicate({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('project/duplicate', payload)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  delete({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`project/delete/${id}`)
        .then(() => {
          commit('admin/collections/DELETE_TEMPLATE', id, { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  share({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('project/makePublic', payload)
        .then((response) => {
          commit('SET_PUBLIC_URL', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveAsTemplate({ commit }, project) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('project/saveAsTemplate', project)
        .then((response) => {
          commit('admin/collections/SET_TEMPLATE', response.data, { root: true });
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_RECENT(state, payload) {
    state.recent = payload;
  },

  SET_DESIGNS(state, payload) {
    state.designed = payload;
    state.loadedAt = Date.now();
  },

  SET_UPLOADS(state, payload) {
    state.uploaded = payload;
  },

  SET_SELECTED(state, project) {
    state.selected = project;
  },

  SET_NAME(state, name) {
    state.selected.name = name;
  },

  SET_PUBLIC_URL(state, item) {
    state.selected = Object.assign(state.selected, item);
  },

  SET_PROJECT_PREVIEW(state, payload) {
    if (state.selected && state.selected.id === payload.id) {
      state.selected.previewUrl = payload.url;
    }
    var index = state.designed.findIndex((x) => x.id === payload.id);
    if (index > -1) state.designed[index].previewUrl = payload.url;
    else {
      index = state.uploaded.findIndex((x) => x.id === payload.id);
      if (index > -1) state.uploaded[index].previewUrl = payload.url;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
