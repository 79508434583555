import { render, staticRenderFns } from "./CartItem.vue?vue&type=template&id=f789f04c&scoped=true&"
import script from "./CartItem.vue?vue&type=script&lang=js&"
export * from "./CartItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f789f04c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VExpandTransition,VImg,VSheet})
