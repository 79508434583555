<template>
  <v-tooltip
    v-model="showCopied"
    top
    :position-x="tooltipX"
    :position-y="tooltipY"
    color="black"
  >
    <span>Copied!</span>
  </v-tooltip>
</template>

<script>
export default {
  data: () => ({
    tooltipX: 0,
    tooltipY: 0,
    showCopied: false
  }),
  methods: {
    copy(x, y, value) {
      this.showCopied = true;
      this.tooltipX = x;
      this.tooltipY = y;

      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = value;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);

      setTimeout(() => {
        this.showCopied = false;
      }, 2000);
    }
  }
};
</script>
