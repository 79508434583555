export default {
  required: (v) => !!v || 'Required',

  requiredIndex: (v) => (v == null ? -1 : v) >= 0 || 'Required',

  minlength: (v, length) =>
    v.length >= length || 'Must be contain at least ' + length + ' characters',

  phone: (v) =>
    /^\d{3}[-.]?\d{3}[-.]?\d{4}$/.test(v) || !v || 'Invalid phone number (XXX-XXX-XXXX)',

  email: (v) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
      v
    ) || 'Invalid Email',

  emailOrEmpty: (v) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
      v
    ) || !v || 'Invalid Email',

  emailDomain: (v) =>
    /^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
      v
    ) || 'Invalid Email Domain (@company.com)',

  url: (v) => {
    return (
      /^((?!-)[A-Za-z0–9-]{1, 63}(?<!-)\.)+[A-Za-z]{2, 6}$/.test(v) ||
      'Please enter a valid website address'
    );
  },

  password: (v) => {
    const pattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_.])(?=.{8,})/;
    return (
      !v ||
      pattern.test(v) ||
      'Min. 8 characters with at least one capital letter, a number and a special character (!,@,#,$,%,^,&,*,_,.).'
    );
  },

  double: (v) => {
    return /^[0-9]+(.[0-9]+)?$/.test(v) || 'Invalid number';
  },

  int: (v) => {
    return /^[0-9]+$/.test(v) || 'Invalid number';
  },

  imageSize: (files) => {
    return (
      !files ||
      files.reduce((size, file) => size + file.size, 0) < 15 * 1024 * 1024 ||
      'Images must be less than 15 MB'
    );
  }
};
